.custom-multiple-input {
    margin: 0 0 10px;
    max-width: 100%;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-align: left;
    line-height: 1.21428571em;
    padding: 0.67857143em 1em;
    background: #fff;
    border: 1px solid rgba(34, 36, 38, .15);
    color: rgba(0, 0, 0, .87);
    border-radius: 0.28571429rem;
    transition: box-shadow .1s ease, border-color .1s ease;
    box-shadow: none;
}

.multiple-value-text-input-item-container {
    text-align: left;
    padding: 5px 0 0;
}

.multiple-value-text-input .multiple-value-text-input-item {
    padding: 2px !important;
    margin: 5px 5px 0 0;
    background: transparent !important;
    display: inline-block;
    border: 1px solid rgba(34, 36, 38, .15);
    border-radius: 0.28571429rem;
}