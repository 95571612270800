.ui.checkbox.as-radio input[type='checkbox'] ~ label:before {
    border-radius: 500rem;
}

.ui.checkbox.as-radio input[type='checkbox']:checked ~ label:after {
    content: none;
}

.ui.input > input:focus {
    border-color: #3498ff !important;
    box-shadow: 0 0 0 3px rgb(52 152 255 / 25%) !important;
    transition: border-color .3s ease-in-out;
}

.ui.checkbox input:focus + label:before {
    border-color: #3498ff !important;
    box-shadow: 0 0 0 3px rgb(52 152 255 / 25%) !important;
}

.ui.checkbox input:checked:focus + label:before {
    border-color: #2b931b !important;
}

.ui.selection.dropdown:focus {
    border-color: #3498ff !important;
    box-shadow: 0 0 0 3px rgb(52 152 255 / 25%) !important;
}

.ui.selection.dropdown:focus .visible.menu {
    border-color: #3498ff !important;
    box-shadow: 0 1px 0 3px rgb(52 152 255 / 25%) !important;
}